<template>
<svg width="1600px" height="900px" viewBox="0 0 1600 900" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="50%" gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.980555),translate(-0.500000,-0.500000)" id="radialGradient-3">
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" stop-opacity="0.466182255" offset="100%"></stop>
        </radialGradient>
        <path d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0" id="path-4"></path>
        <filter x="-101.6%" y="-70.7%" width="303.1%" height="248.3%" filterUnits="objectBoundingBox" id="filter-5">
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Casinos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="NYNY">
            <g id="path-nyny">
                <g id="Route" stroke-linecap="round" stroke-linejoin="round">
                    <filter id="glow-1" x="-2" y="-2" width="200" height="200">
                        <feGaussianBlur stdDeviation="5"/>
                    </filter>
                    <path filter="url(#glow-1)" stroke="#FFFFFF" stroke-width="6.00000024" d="M877.600005,277 L873.400005,290.800001 L873.400005,334.692577 C873.161981,343.997265 868.861654,348.649608 860.499023,348.649608 C852.136393,348.649608 846.061198,348.316275 842.273438,347.649608 L773.65918,352.693554 L740.167969,353.693554 L701.84668,353.693554 L622.107422,359.593944 C619.153003,371.067773 627.117196,385.703125 646,403.5 C674.324219,430.195313 686.537109,444.457031 686.537109,484.150391 C686.537109,510.61263 686.537109,585.395833 686.537109,708.5 L619,708.5" class="path-glow"></path>
                    <path stroke="#FFFFFF" stroke-width="6.00000024" d="M877.600005,277 L873.400005,290.800001 L873.400005,334.692577 C873.161981,343.997265 868.861654,348.649608 860.499023,348.649608 C852.136393,348.649608 846.061198,348.316275 842.273438,347.649608 L773.65918,352.693554 L740.167969,353.693554 L701.84668,353.693554 L622.107422,359.593944 C619.153003,371.067773 627.117196,385.703125 646,403.5 C674.324219,430.195313 686.537109,444.457031 686.537109,484.150391 C686.537109,510.61263 686.537109,585.395833 686.537109,708.5 L619,708.5" class="path-line"></path>
                </g>
                <g id="Ending-Point" transform="translate(603.800004, 694.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019"></circle>
                </g>
                <image id="Bitmap" x="477" y="642" width="157" height="141" href="@/assets/images/icons/end.png"></image>
                <g id="Starting-Point" transform="translate(861.800004, 246.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="27.6000011" r="4.80000019"></circle>
                    <g id="Fill-1">
                        <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
                        <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
                    </g>
                </g>
                <text id="New-York,-New-York" font-family="sans-serif" font-size="14.4000006" font-weight="normal" line-spacing="15.6000006" fill="#FFFFFF">
                    <tspan x="904" y="278">New York, New York</tspan>
                </text>
                <rect id="Rectangle" fill="#000000" x="1599" y="899" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="1599" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="899" width="1" height="1"></rect>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    name: "NewYorkPath",
    mounted() {
        var path = document.querySelector('.path-line')
        var length = path.getTotalLength()
        path.style.transition = path.style.WebkitTransition = 'none'
        path.style.strokeDasharray = length + ' ' + length
        path.style.strokeDashoffset = length
        path.getBoundingClientRect()
        path.style.transition = path.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        path.style.strokeDashoffset = '0'

        var pathGlow = document.querySelector('.path-glow')
        var lengthGlow = pathGlow.getTotalLength()
        pathGlow.style.transition = pathGlow.style.WebkitTransition = 'none'
        pathGlow.style.strokeDasharray = lengthGlow + ' ' + lengthGlow
        pathGlow.style.strokeDashoffset = lengthGlow
        pathGlow.getBoundingClientRect()
        pathGlow.style.transition = pathGlow.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        pathGlow.style.strokeDashoffset = '0'
    }
}
</script>

<style lang="scss" scoped>

</style>